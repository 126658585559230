import styled, { css } from 'styled-components';
import { color, font, vw, mediaBreakpointUp, mediaBreakpointDown, adaptiveHardValues, hover } from '@/style/mixins';

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-shrink: 0;
    width: 100%;
    margin-top: 40px;

    ${mediaBreakpointUp('md')} {
        margin-top: 60px;
    }

    ${mediaBreakpointUp('xl')} {
        margin-top: auto;
    }
`;

export const SearchProceduresHeader = styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    ${adaptiveHardValues('gap', 24, 16, 12)};

    ${mediaBreakpointDown('md')} {
        flex-direction: column;
    }
`;

export const SearchProceduresInput = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex: 1;
    width: 100%;

    ${mediaBreakpointUp('md')} {
        width: auto;
    }

    ${mediaBreakpointUp('xl')} {
        max-width: 472px;
    }

    input {
        width: 100%;
        padding: 17px 20px 17px 40px;
        ${font('text2-sb')};
        line-height: 1;
        color: ${color('ui-text-default')};
        background-color: ${color('ui-bg')};
        outline: none;
        border: none;
        border-radius: 14px;

        ${mediaBreakpointUp('md')} {
            padding: 19px 20px 19px 44px;
        }

        &::placeholder {
            color: ${color('ui-label-default')};
        }

        &::-ms-clear {
            display: none;
            height: 0;
            width: 0;
        }
    }
`;

export const SearchProceduresButtons = styled.div`
    position: absolute;
    top: 50%;
    right: 4px;
    display: flex;
    align-items: center;
    transform: translateY(-50%);

    button {
        flex-shrink: 0;
        height: 52px;
        padding: 0 24px;
        ${font('ui')};
        color: ${color('text-oncolor-primary')};
        background-color: ${color('DeepBlue/900')};
        border-radius: 10px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;

        ${hover(`
            background-color: ${color('DeepBlue/400')};
        `)};

        ${mediaBreakpointDown('md')} {
            display: none;
        }
    }
`;

export const SearchProceduresClear = styled.div`
    position: relative;
    width: 14px;
    height: 14px;
    margin-right: 12px;
    opacity: 0.3;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    ${mediaBreakpointUp('md')} {
        margin-right: 20px;
    }

    ${mediaBreakpointUp('xl')} {
        width: 18px;
        height: 18px;
    }

    ${mediaBreakpointUp('fhd')} {
        width: ${vw(18)};
        height: ${vw(18)};
    }

    &:hover {
        opacity: 1;
    }

    &::after,
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 2px;
        height: 100%;
        background-color: ${color('black')};
    }

    &::after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;

export const SearchProceduresTags = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 24px;
`;

export const SearchProceduresTag = styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    ${font('text3-sb')};
    color: ${({ active }) => (active ? `${color('brandPrimary')}` : `${color('ui-text-default')}`)};
    background-color: ${color('ui-bg')};
    border-radius: 35px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    white-space: nowrap;
    user-select: none;

    &:hover {
        ${({ active }) =>
            !active &&
            css`
                background-color: ${color('ui-bg-hover')};
            `}
    }
`;

export const SearchProceduresLink = styled.a`
    display: flex;
    align-items: center;
    margin-top: 24px;
    ${font('text2-sb')};
    color: ${color('link-default')};
    transition: color 0.3s ease-in-out;

    ${mediaBreakpointUp('xl')} {
        margin-top: 32px;
    }

    &:hover {
        color: ${color('link-hover')};
    }

    svg {
        display: block;
        width: 8px;
        height: 12px;
        margin-left: 10px;
        stroke: currentColor;
        transition: stroke 0.3s ease-in-out;
    }
`;

export const SearchProceduresIcon = styled.button`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 35px;
    height: 100%;
    padding: 0;
    background-color: transparent;

    ${mediaBreakpointUp('md')} {
        width: 45px;
    }

    svg {
        width: 14px;
        height: 14px;

        ${mediaBreakpointUp('md')} {
            width: 16px;
            height: 16px;
        }

        path {
            fill: ${color('text-primary')};
        }
    }
`;
