import styled from 'styled-components';
import { color } from '@/style/mixins';

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const PaginationContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 100%;
    display: flex;
    transform: translateX(-50%);

    span {
        display: none;
        width: 16px;
        height: 2px;
        margin: 0 2px;
        background-color: ${color('white')};
        border-radius: 86px;
        opacity: 0.2;
        cursor: pointer;

        &.visible {
            display: inline-block;
        }
    }

    span.swiper-pagination-bullet-active {
        width: 42px;
        opacity: 1;
        cursor: default;
    }
`;
