import React, { useEffect, useState } from 'react';
import { Container, PaginationContainer } from './SimpleSlider.styled';
import { BaseOffsetsUI, BaseUI } from '@/interfaces';
import { Swiper } from 'swiper/react';
import SwiperCore, { SwiperOptions, Pagination, Thumbs, Autoplay } from 'swiper';

SwiperCore.use([Autoplay, Pagination, Thumbs]);

const SWIPER_OPTIONS: SwiperOptions = {
    slidesPerView: 'auto',
    speed: 800,
    keyboard: true
};

interface SimpleSliderProps extends BaseUI, BaseOffsetsUI {
    swiperOptions?: SwiperOptions;
}

const SimpleSlider: React.FC<SimpleSliderProps> = ({ children, className, swiperOptions }) => {
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);
    const optionsSlider: SwiperOptions = { ...SWIPER_OPTIONS, ...swiperOptions };

    const updateThumbsActiveClass = () => {
        if (!swiper) return;

        const bullets = swiper.pagination.bullets;
        const groupNumber = Math.ceil((swiper.realIndex + 1) / 4);

        bullets.forEach((bullet) => {
            // @ts-ignore
            bullet.classList.remove('visible');
        });

        const startIndex = (groupNumber - 1) * 4;
        const endIndex = startIndex + 4;

        for (let i = startIndex; i < endIndex; i++) {
            if (bullets[i]) {
                // @ts-ignore
                bullets[i].classList.add('visible');
            }
        }
    };

    useEffect(() => updateThumbsActiveClass(), [swiper]);

    return (
        <Container className={className}>
            <Swiper
                onSwiper={setSwiper}
                {...optionsSlider}
                pagination={{
                    el: '.pagination',
                    clickable: true,
                    renderBullet: (_, className) => `<span class="${className}"></span>`
                }}
                onSlideChange={updateThumbsActiveClass}
            >
                {children}
            </Swiper>
            <PaginationContainer className="pagination" />
        </Container>
    );
};

export default SimpleSlider;
