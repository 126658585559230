import styled from 'styled-components';
import { color, mediaBreakpointUp, offset } from '@/style/mixins';
import { Colors } from '@/style/colors';
import { BaseOffsetsUI } from '@/interfaces';

export const Container = styled.div<
    BaseOffsetsUI & {
        sideOffsetProperty: 'padding' | 'margin';
        verticalOffsetProperty: 'padding' | 'margin';
        hasSideOffsets?: boolean;
        bgColor?: Colors;
        dFlex?: boolean;
    }
>`
    position: relative;
    ${({ topOffset, verticalOffsetProperty }) => topOffset && offset(topOffset, `${verticalOffsetProperty}-top`)}
    ${({ bottomOffset, verticalOffsetProperty }) => bottomOffset && offset(bottomOffset, `${verticalOffsetProperty}-bottom`)}
    ${({ bgColor }) => bgColor && `background-color: ${color(bgColor)};`}
    ${({ dFlex }) => dFlex && `display: flex;`}
    ${({ hasSideOffsets }) => hasSideOffsets && 'padding-left: 20px; padding-right: 20px;'}

    ${mediaBreakpointUp('xl')} {
        ${({ hasSideOffsets }) => hasSideOffsets && 'padding-left: 0; padding-right: 0;'}
    }
`;
