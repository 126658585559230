import styled from 'styled-components';
import { mediaBreakpointUp, font, color, hover, offset } from '@/style/mixins';
import { Container as PrimaryCard, CardTitle } from '@/componentsNew//blocks/PrimaryCard/PrimaryCard.styled';
import { Container as SimpleSlider, SimpleSliderPagination } from '@/components/common/SimpleSlider/SimpleSlider.styled';
import { BaseOffsetsUI } from '@/interfaces';
import Image from '@/components/common/Image/Image';

export const Container = styled.div<BaseOffsetsUI>`
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    gap: 40px;
    ${({ topOffset }) => topOffset && offset(topOffset, 'padding-top')};
    ${({ bottomOffset }) => bottomOffset && offset(bottomOffset, 'padding-bottom')};
    padding-left: 20px;
    padding-right: 20px;

    ${mediaBreakpointUp('md')} {
        gap: 60px;
    }

    ${mediaBreakpointUp('xl')} {
        justify-content: space-between;
        grid-template-columns: 532px 592px;
        padding-left: 0;
        padding-right: 0;
        gap: 0;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    ${SimpleSlider} {
        padding: 0;
        overflow: hidden;
    }

    ${SimpleSliderPagination} {
        ${offset('s', 'margin-top')};

        ${mediaBreakpointUp('xl')} {
            display: flex;
        }
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        ${font('h1')};
        ${offset('l', 'margin-bottom')};
        max-width: 335px;

        ${mediaBreakpointUp('md')} {
            max-width: 542px;
        }
    }

    span {
        ${font('h3')};
        margin-bottom: 20px;

        ${mediaBreakpointUp('md')} {
            margin-bottom: 24px;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 12px;

        ${mediaBreakpointUp('md')} {
            max-width: 542px;
        }

        li {
            ${font('t1-body-sb')};
        }
    }
`;

export const CardsContainer = styled.div<{ length: number }>`
    display: grid;
    gap: 12px;

    ${mediaBreakpointUp('md')} {
        gap: 16px;
        grid-template-columns: ${({ length }) => (length === 3 ? `repeat(2, 1fr)` : `1fr`)};
    }

    ${PrimaryCard} {
        grid-column: ${({ length }) => (length === 3 ? `span 1` : `span 2`)};

        &:first-child {
            ${mediaBreakpointUp('md')} {
                grid-column: span 2;
            }

            ${CardTitle} {
                ${font('h2')};
            }
        }
    }
`;

export const CompaniesContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 20px;

    ${mediaBreakpointUp('md')} {
        flex-direction: row;
    }

    ${mediaBreakpointUp('xl')} {
        flex-direction: row-reverse;
    }

    span {
        ${font('text2-sb')};
        color: ${color('text-tertiary')};
        display: inline-block;
    }

    ul {
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        li {
            width: 54px;
            height: 54px;

            ${mediaBreakpointUp('xl')} {
                width: 64px;
                height: 64px;
            }

            &:not(:first-child) {
                margin-left: -24px;
            }
        }
    }
`;

export const CompaniesImage = styled(Image)`
    width: inherit;
    height: inherit;
`;

export const BottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex-grow: 1;
    margin-top: 20px;

    ${mediaBreakpointUp('md')} {
        margin-top: 24px;
        flex-direction: row;
        justify-content: space-between;
    }

    ${mediaBreakpointUp('xl')} {
        margin-top: 40px;
        flex-direction: column;
    }

    a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 54px;
        padding: 0 20px;
        ${font('text2-sb')};
        border-radius: 10px;
        background-color: ${color('Content-02')};
        transition: background-color 0.3s ease-in-out;

        ${hover(`background-color: ${color('Content-02-hover')}`)};

        ${mediaBreakpointUp('md')} {
            padding-right: 24px;
        }

        ${mediaBreakpointUp('xl')} {
            padding-left: 24px;
            padding-right: 32px;
        }

        svg {
            width: 29px;
            height: 29px;
            position: absolute;
            bottom: -1px;
            right: -1px;
            transform: rotate(-90deg);
            fill: ${color('light-blue-background')};
        }
    }
`;
