import styled from 'styled-components';
import { font, color, mediaBreakpointUp, mediaBreakpointDown, hover } from '@/style/mixins';
import Image from '@/components/common/Image/Image';
import Card2 from '@/componentsNew/blocks/Cards/Card2/Card2';
import { PaginationContainer } from '@/componentsNew/blocks/SimpleSlider/SimpleSlider.styled';

export const Left = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    background-color: ${color('Content-01')};
    border-radius: 18px;

    ${mediaBreakpointUp('md')} {
        padding: 24px;
    }

    ${mediaBreakpointUp('xl')} {
        width: 795px;
        padding: 32px;
    }
`;

export const Title = styled.h2`
    ${font('h1')};
`;

export const Text = styled.p`
    ${font('t1-body-sb')};
    margin-top: 20px;

    ${mediaBreakpointUp('md')} {
        max-width: 457px;
        margin-top: 24px;
    }
`;

export const Container = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 92px 20px 40px;

    ${mediaBreakpointUp('md')} {
        gap: 16px;
        padding: 116px 20px 60px;
    }

    ${mediaBreakpointUp('xl')} {
        padding: 168px 0 80px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        background-color: ${color('Bg-Dark')};
        width: 100vw;
        height: 100%;
        border-radius: 0 0 20px 20px;
        transform: translateX(-50%);
    }
`;

export const LeadLinks = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;

    ${mediaBreakpointUp('md')} {
        width: auto;
    }

    ${mediaBreakpointUp('xl')} {
        gap: 12px;
    }
`;

export const LeadLinkLogo = styled(Image)`
    width: 100%;
    background-color: ${color('ui-bg')};
    border-radius: 12px;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const LeadLinkTitle = styled.span`
    ${font('text4')};
    color: ${color('text-tertiary')};
    white-space: nowrap;

    ${mediaBreakpointDown('md')} {
        display: none;
    }
`;

export const LeadLink = styled.a`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    width: 100%;

    ${hover(`
        ${LeadLinkLogo} {
            background-color: ${color('ui-bg-hover')};
        }
    `)};

    ${mediaBreakpointUp('md')} {
        width: 60px;
    }
`;

export const Angle = styled.div`
    position: absolute;
    bottom: -1px;
    right: -1px;
    transform: rotate(-90deg);
    z-index: 1;
    display: block;
    width: 48px;
    height: 48px;
    user-select: none;

    path {
        fill: ${color('Bg-Dark')};
    }

    ${mediaBreakpointDown('md')} {
        display: none;
    }
`;

export const Right = styled.div`
    width: calc(100vw - 20px);

    ${mediaBreakpointUp('md')} {
        width: 100%;
    }

    ${mediaBreakpointUp('xl')} {
        width: 389px;
    }

    ${PaginationContainer} {
        margin-top: 16px;
        margin-left: -10px;

        ${mediaBreakpointUp('md')} {
            margin-top: 20px;
            margin-left: auto;
        }
    }
`;

export const SlideColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: inherit;
    justify-content: space-between;
`;

export const Slide = styled(Card2)`
    height: 140px;

    ${mediaBreakpointUp('md')} {
        height: 166px;
    }
`;
